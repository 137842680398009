export const {
  ADDITIONAL_RECAPTURE_ALLOWED = null,
  ALLOW_MOBILE_EMULATORS = false,
  ALLOWED_USER_AGENTS_ON_IOS = [],
  ALT_TEXT_LOGO = '',
  ANIMATION_FILE_URLS_EXTEND = {},
  ANIMATION_OVERWRITTEN_BY_FLOW = {},
  APP_LOGO = '/assets-mobile/images/logos/logo.png',
  APP_LOGO_SUCCESS = '/assets-mobile/images/logos/logo-success.png',
  BACK_BUTTON_ICON_ON_HEADER = '',
  CANCEL_TXN_FOR_NOT_ELIGIBLE_AGE = false,
  CAPTURE_ADDITIONAL_DOCS_MAX_LIMIT = 10,
  CHECK_DEVICE = false,
  COUNTRIES = [
    {
      name: 'Australia',
      iso: 'AU',
      flag: 'australia.svg'
    },
    {
      name: 'New Zealand',
      iso: 'NZ',
      flag: 'new-zealand.svg'
    },
    {
      name: 'China',
      iso: 'CN',
      flag: 'china.svg'
    },
    {
      name: 'Hong Kong',
      iso: 'HK',
      flag: 'hong-kong.svg'
    },
    {
      name: 'Malaysia',
      iso: 'MY',
      flag: 'malaysia.svg'
    },
    {
      name: 'Singapore',
      iso: 'SG',
      flag: 'singapore.svg'
    },
    {
      name: 'International',
      iso: 'OTHER',
      flag: 'world.svg'
    }
  ],
  COUNTRY = 'AU',
  DATA_CHECK_ATTEMPT_COUNT = 2,
  DEFAULT_COUNTRY_CODE = 'AU',
  DEFAULT_COUNTRY_LABEL = 'Australia',
  DEFAULT_LANGUAGE = 'en',
  DETAIL_MATCH = [],
  DETAIL_MATCH_ONLY_UNIVERSAL_URL = false,
  DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY = false,
  DISABLE_COUNTRY_SELECT = false,
  DL_NUMBER_VALIDATION = false,
  ENABLE_BACK_BUTTON_ON_HEADER = false,
  ENABLE_CONFIRM_AGE = true,
  ENABLE_ENTER_ADDRESS_MANUALLY = true,
  ENABLE_ONE_DOC_CONDITION = false,
  ENABLE_ROUND_BUTTON = true,
  ENABLE_STATISTICS = true,
  ENABLE_VISA_AFTER_PASSPORT = false,
  EVISA_CAPTURE_PHOTO = false,
  VISA_AFTER_PASSPORT_ADDITIONAL_FIELDS = [
    {
      id: 'visaStartDate',
      date: true
    },
    {
      id: 'visaEndDate',
      date: true
    }
  ],
  FEEDBACK_LINK,
  FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED = true,
  FLOW_V2_ALLOW_EXPIRED_TYPES = [],
  FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY = [],
  FLOW_V2_CHOOSE_ANOTHER_ID_AVAILABLE_LIST = [
    {
      type: 'Driver Licence',
      title: 'Driver Licence',
      icon: 'icon_card.svg',
      checked: false
    },
    {
      type: 'PASSPORT',
      title: 'Passport',
      icon: 'icon_passport.svg',
      checked: false
    },
    {
      type: 'PROOF_OF_AGE',
      title: 'Proof of Age Card',
      icon: 'icon_card.svg',
      checked: false
    }
  ],
  FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC = false,
  FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_EXIT_BUTTON_ENABLED = false,
  FLOW_V2_DATEPICKER_FORMAT,
  FLOW_V2_DIGITAL_DL_HIDE_CARD_NUMBER_FIELD = false,
  FLOW_V2_DOC_NOT_ACCEPTED_TRY_AGAIN_MODE = false,
  FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE = false,
  FLOW_V2_EXCEPTION_ALIGN_CENTER = true,
  FLOW_V2_EXCEPTION_ISSUE_ICON = 'images/icons/png/issue-videoid.png',
  FLOW_V2_EXCEPTION_SHOW_ICON = true,
  FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE = false,
  FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE = false,
  FLOW_V2_EXTRA_FR_TIPS,
  FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2,
  FLOW_V2_FORCE_ALTERNATE_FLOW = false,
  FLOW_V2_HIDE_IMAGES_IN_DOC_LIST,
  FLOW_V2_HIDE_ADDRESS_IF_NOT_ON_ID = false,
  FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS = true,
  FLOW_V2_LOADING_DETAILS_PROGRESS_BAR = true,
  FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME = false,
  FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT = true,
  FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME = true,
  FORCE_CAPTURE_ADDITIONAL_DOCS = false,
  VOI_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME = false,
  HIDDEN_PRIVACY_IN = [],
  HIDE_FOOTER_UPLOAD_SPINNER = false,
  HIDE_PRIVACY_CHECKBOX = false,
  ID_NUMBER_EDITABLE = true,
  IMMEDIATE_REDIRECT = false,
  LICENCEVERSION = true,
  LOADING_SPINNER_ANIMATION_SIZE_SCALE = 1,
  LOADING_SPINNER_ANIMATION_URL,
  LOADING_SPINNER_HIDE_HEAD_MESSAGES = false,
  LOADING_SPINNER_SHOW_TOP_HEADER = false,
  LOCATION = true,
  LOCATION_REQUIRED = false,
  MULTI_LANGUAGE = false,
  NON_MOBILE_DEVICE_FALLBACK = 'QR',
  OPEN_ADDRESS_LOOK_UP_OPTIONS_IF_NO_MATCH = true,
  REDUCE_FACE_ANIMATION_SIZE,
  REMOVE_ADDRESS_REVIEW = false,
  SELECT_COUNTRIES = [],
  SEND_SMS_BACK_BUTTON = false,
  SHOW_ENABLE_CAMERA_GRAPHIC = false,
  SUCCESS_ANIMATION_SIZE_SCALE = 1,
  SUCCESS_SCREEN_HIDE_HEADER = false,
  TICK_ICON_COLOR = '#00D996',
  TURKISH_LICENCE_B_CLASS_ENABLED = false,
  UNIVERSAL_URL_PATH = '/verify',
  VOI_FLOW_V2_ADDITIONAL_DOC = true,
  VOI_FLOW_V2_ALLOW_EXPIRED_TYPES = [],
  VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY = [],
  VOI_FLOW_V2_AUS_AUTO_BIRTH = 'Birth certificate',
  VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE = 'Driver Licence',
  VOI_FLOW_V2_AUS_CENTRELINK = 'Centrelink',
  VOI_FLOW_V2_AUS_CERT_CITIZENSHIP = 'Citizenship certificate',
  VOI_FLOW_V2_AUS_CERT_DESCENT = 'Descent certificate',
  VOI_FLOW_V2_AUS_DEFENCE_FORCE = 'Defence Force',
  VOI_FLOW_V2_AUS_MEDICARE = 'Medicare card',
  VOI_FLOW_V2_AUTO_SELECT_DOCS = [],
  VOI_FLOW_V2_AVAILABLE_DOCS1 = [
    {
      type: 'Driver Licence',
      title: 'Australian Driver Licence',
      icon: 'icon_card.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'DIGITALID',
      title: 'Digital Driver Licence',
      icon: 'icon_card.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'PASSPORT',
      title: 'Passport',
      icon: 'icon_passport.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'AUS_IMMICARD',
      title: 'ImmiCard',
      icon: 'icon_card.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'PROOF_OF_AGE',
      title: 'Proof of age card',
      icon: 'icon_card.svg',
      checked: false,
      ocr: true
    }
  ],
  VOI_FLOW_V2_AVAILABLE_DOCS2 = [
    {
      type: 'Medicare',
      title: 'Medicare card',
      icon: 'icon_card.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'AUS_AUTO_BIRTH',
      title: 'Birth certificate',
      icon: 'icon_cert.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'CITIZENSHIP_CERT',
      title: 'Citizenship certificate',
      icon: 'icon_cert.svg',
      checked: false,
      ocr: true
    },
    {
      type: 'AUS_CERT_DESCENT',
      title: 'Descent certificate',
      icon: 'icon_cert.svg',
      checked: false,
      ocr: false
    },
    {
      type: 'Centrelink',
      title: 'Centrelink',
      icon: 'icon_card.svg',
      checked: false,
      ocr: false
    },
    {
      type: 'Defence Force ID',
      title: 'Defence Force',
      icon: 'icon_card.svg',
      checked: false,
      ocr: false
    },
    {
      type: 'AUS_CERT_DESCENT',
      title: 'Descent Certificate',
      icon: 'icon_card.svg',
      checked: false,
      ocr: false
    },
    {
      type: 'AUS_AUTO_MARRIAGE',
      title: 'Marriage certificate',
      icon: 'icon_cert.svg',
      checked: false,
      ocr: false
    },
    {
      type: 'AUS_AUTO_NAME',
      title: 'Change of name certificate',
      icon: 'icon_cert.svg',
      checked: false,
      ocr: false
    }
  ],
  VOI_FLOW_V2_FILTER_DOCS = [
    {
      ifSelected: ['DIGITALID'],
      filterOut: ['Driver Licence']
    },
    {
      ifSelected: ['Driver Licence'],
      filterOut: ['DIGITALID']
    }
  ],
  VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN = true,
  VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN = false,
  VOI_FLOW_V2_NAME_MATCH_DOCS = null,
  VOI_FLOW_V2_NAME_MATCH_SHOW_SKIP_THIS_STEP = true,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1 = null,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2 = null,
  VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST = [],
  VOI_FLOW_V2_PROOF_OF_AGE = 'Proof of age card',
  VOI_FLOW_V2_REQUIRED_DOC_CONFIG = [
    {
      numberOfDocsRequired: 2,
      possibleDocs: ['Driver Licence', 'DIGITALID', 'PASSPORT']
    },
    {
      numberOfDocsRequired: 3
    }
  ],
  VOI_FLOW_V2_SHOW_WELCOME = false,
  VOICE_PROMPTS_SYNC = null // TODO: deprecate this
} = process.env;

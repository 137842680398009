import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SuccessContent from '@lib/components/v2/SuccessFlowV2';
import { getLocalizedIdvcContent } from '@languages';

function Success({ appConfig, onHandleRedirection = null, redirect = false }) {
  const props = {
    successMessage: null,
    redirect,
    onHandleRedirection
  };
  if (appConfig.success_message) {
    props.successMessage = getLocalizedIdvcContent(appConfig.success_message);
  }
  return <SuccessContent {...props} />;
}

Success.propTypes = {
  appConfig: PropTypes.object,
  redirect: PropTypes.bool,
  onHandleRedirection: PropTypes.func
};

export default connect(mapStateToProps, null)(Success);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lib/components/v2/Title';
import { localizedString } from '@languages';
import classes from './CaptureVisa.style.module.scss';

const CaptureVisa = ({ buttons }) => {
  return (
    <div className={classes.wrapper}>
      <Title
        className={classes.heading}
        title={localizedString('passporNotFromNewZealandOrFromAustralia')}
      />
      <div className={classes.description}>{localizedString('choosePhysicalVisaOrEvisa')}</div>

      <div className={classes.footer}>
        {buttons.map((button, i) => (
          <button
            key={button.label}
            type="button"
            className={i > 0 ? classes.second_button : classes.first_button}
            onClick={button.onClick}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
};

CaptureVisa.propTypes = {
  buttons: PropTypes.array
};

CaptureVisa.defaultProps = {
  buttons: []
};

export default CaptureVisa;

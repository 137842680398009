import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import APIs from '@services/APIs';
import PrivacyContent from '@lib/components/v2/PrivacyFlowV2';
import { getLocalizedIdvcContent } from '@languages';
import { HIDE_PRIVACY_CHECKBOX } from '@spotMobileConfig';

export function PrivacyScreen({
  flowType,
  onAccept = () => {},
  onExit = () => {},
  onSelectLanguage = () => {}
}) {
  const appConfig = useSelector(({ appConfig }) => appConfig);

  useEffect(() => {
    APIs.status('termsAndCondition');
  }, []);

  let privacyContent;

  if (appConfig.terms) {
    privacyContent = getLocalizedIdvcContent(appConfig.terms);
  }

  return (
    <PrivacyContent
      privacyContent={privacyContent}
      hidePrivacyCheckBox={HIDE_PRIVACY_CHECKBOX}
      onAccept={() => onAccept()}
      onExit={() => onExit()}
      onSelectLanguage={() => onSelectLanguage()}
      flowType={flowType}
    />
  );
}

PrivacyScreen.propTypes = {
  flowType: PropTypes.string,
  onAccept: PropTypes.func,
  onExit: PropTypes.func,
  onSelectLanguage: PropTypes.func
};

import Message from '@lib/components/v2/Message';
import React, { useEffect, useRef, useState } from 'react';
import {
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  isUCBrowser,
  isWebRTCSupported
} from '@lib/Utils';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const AndroidDeviceIncompatibleOpenChrome = ({ issue = true, ...restOfProps }) => {
  const { id } = document.body.dataset;
  return (
    <Message
      {...restOfProps}
      title={localizedString('errors.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_ANDROID')}
      issue={issue}
    >
      {parse(localizedString('errors.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_ANDROID'))}
      {isUCBrowser() && (
        <>
          <br />
          <br />
          <p>
            {parse(
              localizedString(
                'errors.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_ALTERNATIVE_ANDROID'
              )
            )}
            <br />
            <br />
            <a href={document.location.href + id} className="text-break">
              {document.location.href}
              {id}
            </a>
          </p>
        </>
      )}
    </Message>
  );
};

AndroidDeviceIncompatibleOpenChrome.propTypes = {
  issue: PropTypes.bool
};

export const withDeviceCheck =
  ({ checkWebRTC }) =>
  (OriginalComponent) => {
    function DeviceCheck(props) {
      const [webrtc, setWebrtc] = useState(null);
      const inputRef = useRef();

      useEffect(() => {
        if (checkWebRTC) {
          isWebRTCSupported().then(({ status: compatible, todo, os }) => {
            if (!compatible) {
              setWebrtc(todo);
            }

            if (os === 'iOS') {
              document.addEventListener(
                'touchmove',
                (event) => {
                  if (event.scale !== 1) {
                    event.preventDefault();
                  }
                },
                { passive: false }
              );
            }
          });
        }
      }, []);

      if (webrtc === 'OPEN_SAFARI') {
        const openSafariBtns = [
          {
            children: (
              <a className="link-inside-button" href="x-web-search://" target="_self">
                {localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS')}
              </a>
            ),
            full: true,
            style: { backgroundColor: '#000', borderStyle: 'none' },
            onClick: () => copyOpenSafari(inputRef.current)
          }
        ];
        return (
          <Message
            title={localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {parse(localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS'))}
            <input readOnly className="b-hidden-input" ref={inputRef} />
          </Message>
        );
      }

      if (isAndroidDevice() && !isBrowserPermitted()) {
        const openChromeBtns = [
          {
            label: localizedString('proceed'),
            onClick: () => {
              const { id } = document.body.dataset;
              document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
            }
          }
        ];
        return <AndroidDeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
      }

      return <OriginalComponent {...props} />;
    }

    return DeviceCheck;
  };

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { getLocalizedIdvcContent, localizedString } from '@languages';
import { useSelector } from 'react-redux';
import {
  FLOW_V2_WELCOME_SCREEN_BODY,
  FLOW_V2_WELCOME_SCREEN_BUTTON,
  FLOW_V2_WELCOME_SCREEN_FOOTER,
  FLOW_V2_WELCOME_SCREEN_HEADING
} from '@js/constants/appConfig';
import classes from './Welcome.style.module.scss';

export default function Welcome({ onStart }) {
  const appConfig = useSelector(({ appConfig }) => appConfig);
  const heading = getLocalizedIdvcContent(appConfig[FLOW_V2_WELCOME_SCREEN_HEADING]);
  const body = getLocalizedIdvcContent(appConfig[FLOW_V2_WELCOME_SCREEN_BODY]);
  const footer = getLocalizedIdvcContent(appConfig[FLOW_V2_WELCOME_SCREEN_FOOTER]);
  const button = getLocalizedIdvcContent(appConfig[FLOW_V2_WELCOME_SCREEN_BUTTON]);

  const buttons = [
    {
      label: parse(button || localizedString('welcome.flowV2ButtonText')),
      onClick: () => {
        onStart();
      },
      dataTestId: 'welcome-button'
    }
  ];

  return (
    <Page buttons={buttons}>
      <div className={classes.wrapper}>
        <Title
          className={classes.heading}
          data-testid="welcome-heading"
          title={heading || localizedString('welcome.flowV2Title')}
        />
        <div className={classes.description} data-testid="welcome-description">
          {parse(body || localizedString('welcome.flowV2Description'))}
        </div>
        <div className={classes.space} />
        <div className={classes.hyperlink} data-testid="welcome-hyperlink">
          {parse(footer || localizedString('welcome.flowV2HyperlinkText'))}
        </div>
      </div>
    </Page>
  );
}

Welcome.propTypes = {
  onStart: PropTypes.func.isRequired
};

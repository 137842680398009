import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { UNIVERSAL_URL_PATH } from '@spotMobileConfig';
import { getLanguageItemPerName, setLanguage } from '@languages';
import { setupStore } from './store';
import App from './views/App';

const AppWithProviders = () => {
  useEffect(() => {
    const { languages } = navigator;
    const language = languages
      .map((name) => {
        const noVariant = name.split('-')[0];
        return getLanguageItemPerName(name) || getLanguageItemPerName(noVariant);
      })
      .find((langItem) => !!langItem);
    if (language) setLanguage(language.name);
  }, []);

  return (
    <Provider store={setupStore()}>
      <Router>
        <div>
          <Route exact path="/" component={App} />
          <Route exact path={UNIVERSAL_URL_PATH} component={App} />
        </div>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));
